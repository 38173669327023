import React from "react";
import Helmet from "react-helmet";
import SEO from "../components/SEO";
import Layout from "../layout";
import config from "../../data/SiteConfig";

const SubmissionsPage = () => {
  return (
    <Layout>
      <div className="submissions-container cc-container">
        <Helmet title={`Submissions | ${config.siteTitle}`} />
        <SEO />
        <div className="cc-card">
          <div className="cc-padding cc-support cc-text">
            <p><i>Gabriela & The Inn Between</i> is dedicated to exploring nature and beauty in the every day, and we are looking to feature short prose and poems on the themes of nature, beauty, and folklore in our Guestbook segment. If you are interested in submitting your work, please submit up to two pieces under 500 words each along with a short bio to <a href="mailto:bailey@theinnbetweenpodcast.com">bailey@theinnbetweenpodcast.com</a> with the subject as INN BETWEEN SUBMISSION.”
            <br />
            <br />
            While we are not offering monetary compensation at this time, we can plug your work and your website, put you in our show notes, and offer you an imaginary, all-inclusive stay at The Inn Between. All rights to your work remain yours, and we are happy to accept previously published pieces. We simply seek to read your work aloud on the podcast on an episode of our choosing and create a small narrative to incorporate your piece into the Guestbook per the format of our podcast.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default SubmissionsPage;
